export const courseData = [
    {
      id: 1,
      title: "Photoshop Mastery",
      description: "Advanced manipulations and compositions",
      price: 199,
      hours: 40,
      level: "Intermediate",
      thumbnail: null, // Placeholder for future image
      progress: 65
    },
    {
      id: 2,
      title: "After Effects Professional",
      description: "Stunning motion ads and transitions",
      price: 249,
      hours: 50,
      level: "Advanced",
      thumbnail: null, // Placeholder for future image
      progress: 45
    },
    {
      id: 3,
      title: "Premiere Pro Storytelling",
      description: "Reel editing and storytelling techniques",
      price: 179,
      hours: 35,
      level: "Beginner",
      thumbnail: null, // Placeholder for future image
      progress: 30
    },
    {
      id: 4,
      title: "LinkedIn Professional Branding",
      description: "Stand out and network effectively",
      price: 99,
      hours: 20,
      level: "Beginner",
      thumbnail: null, // Placeholder for future image
      progress: 20
    }
  ];


  export const bundles = [
    {
      id: "b1",
      title: "Beginner Graphic Design Bundle",
      courses: [courseData[0], courseData[1], courseData[2]],
      totalPrice: 399,
    },
    {
      id: "b2",
      title: "Advanced Multimedia Bundle",
      courses: [courseData[3], courseData[1]],
      totalPrice: 449,
    },
  ];
  

 export  const getLevelColor = (level) => {
    switch (level) {
      case "Beginner":
        return "bg-green-100 text-green-800";
      case "Intermediate":
        return "bg-blue-100 text-blue-800";
      case "Advanced":
        return "bg-purple-100 text-purple-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  export const policyContent = {
    termsAndConditions: {
      title: "Terms & Conditions",
      subtitle: "Last Updated: December 2024",
      sections: [
        {
          title: "Acceptance of Terms",
          content: "By accessing our platform, you agree to these terms. These terms govern your use of our educational services and create a binding contract between you and our organization."
        },
        {
          title: "User Eligibility",
          content: "Users must be at least 16 years old. By registering, you confirm you meet age requirements and can form legally binding contracts."
        },
        {
          title: "Course Participation",
          content: "Successful completion requires active participation, submission of assignments, and adherence to academic integrity policies."
        },
        {
          title: "Intellectual Property",
          content: "All course materials, design resources, and curriculum content are protected by copyright and remain the intellectual property of our organization."
        }
      ]
    },
    returnAndRefund: {
      title: "Return & Refund Policy",
      subtitle: "Transparent Learning Guarantee",
      sections: [
        {
          title: "7-Day Free Trial",
          content: "New users can experience our platform with a 7-day free trial. No payment information is required during this period."
        },
        {
          title: "Refund Eligibility",
          content: "Full refunds are available within 14 days of course purchase if less than 20% of course content has been accessed."
        },
        {
          title: "Partial Refund Terms",
          content: "Between 20-50% course completion, 50% refund is available. After 50% completion, no refunds will be processed."
        },
        {
          title: "Cancellation Process",
          content: "Refund requests must be submitted via our support portal. Processing typically takes 5-7 business days."
        }
      ]
    }
  };
  

  export const companyDetails={
    name:"DigitalDo",
    email:"connect@digitaldo.in",
    domain:"digitaldo.in",
    
  }