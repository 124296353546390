import React from 'react';
import { motion } from 'framer-motion';
import { BookOpen, GraduationCap } from 'lucide-react';
import useGetCourses, { useGetMyCourse } from '../../utils/firebaseDataFetch/useGetCourses';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const mockCourses = [
  {
    id: 1,
    title: "Advanced Graphic Design Mastery",
    progress: 75,
    duration: "8 weeks",
    lessons: 24
  },
  {
    id: 2,
    title: "Adobe Premier Pro Fundamentals",
    progress: 45,
    duration: "6 weeks",
    lessons: 18
  },
  {
    id: 3,
    title: "Adobe Photoshop for Thumbnail Design",
    progress: 90,
    duration: "10 weeks",
    lessons: 32
  },
  {
    id: 4,
    title: "Video Editing Basics",
    progress: 30,
    duration: "12 weeks",
    lessons: 40
  }
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const cardVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5
    }
  }
};

const CourseCard = ({ course }) => (
  <motion.div
    variants={cardVariants}
    whileHover={{ y: -5, transition: { duration: 0.2 } }}
    className="h-full bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 border border-violet-50 overflow-hidden flex flex-col"
  >
    <div className="relative aspect-video bg-violet-50 flex items-center justify-center">
      <GraduationCap className="w-20 h-20 text-violet-400" />
      <motion.div
        className="absolute inset-0 bg-violet-600/10"
        whileHover={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      />
    </div>
    
    <div className="p-6 flex-1 flex flex-col">
      <h3 className="text-lg font-semibold text-gray-800 mb-4 line-clamp-2 flex-shrink-0">
        {course?.title}
      </h3>
      
      <div className="mt-auto space-y-4">
        <div className="flex justify-between text-sm text-gray-600">
          <span>{course?.level}</span>
          {/* <span>{course?.category}</span> */}
        </div>
        
        <div className="relative">
          <div className="w-full bg-violet-100 rounded-full h-2.5">
            <motion.div 
              initial={{ width: 0 }}
              animate={{ width: `${course.progress}%` }}
              transition={{ duration: 0.8, ease: "easeOut" }}
              className="bg-gradient-to-r from-violet-600 to-violet-400 h-2.5 rounded-full"
            />
          </div>
          <div className="mt-2 flex justify-between items-center">
            <span className="text-sm font-medium text-violet-600">
              Progress
            </span>
            <span className="text-sm font-medium text-violet-600">
              {course.progress}%
            </span>
          </div>
        </div>
      </div>
    </div>
  </motion.div>
);

const MyCourses = () => {

  useGetMyCourse()

  const myCourses=useSelector(state=>state.users.myCourse)
  



  return (
    <div className="p-6 bg-gray-50/30">
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mb-8 flex items-center justify-between"
      >
        <h2 className="text-2xl font-bold text-gray-800 flex items-center gap-3">
          <BookOpen className="text-violet-600" size={28} />
          My Courses
        </h2>
      </motion.div>
      
      <motion.div 
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6"
      >
        {myCourses.map(course => (
          <Link key={course.id} to={`course/${course?.id}/${course?.title}`}>
          <CourseCard  course={course} />
          </Link>
        ))}
      </motion.div>
    </div>
  );
};

export default MyCourses;