import React from 'react';
import { Home, Settings, Telescope, TvMinimalPlay, User } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import UserProfile from './subComponents/UserProfle';
import { useSelector } from 'react-redux';
import useGetCourses from '../utils/firebaseDataFetch/useGetCourses';

const SideBarLMS = ({isCollapsed, setIsCollapsed, isActiveSection="overview",setIsActiveSection}) => {
  const getUser = useSelector((state) => state.users.user);

  const sideBarSections = [
    {
      id: "overview",
      icon: <Home className="w-6 h-6" />,
      label: "Overview",
    },
    {
      id: "courses",
      icon: <TvMinimalPlay className="w-6 h-6" />,
      label: "My Courses",
    },
    {
      id: "workshop",
      icon: <Telescope className="w-6 h-6" />,
      label: "Workshop",
    }
  ];

  const toggleSidebar = () => setIsCollapsed(!isCollapsed);

  const sidebarVariants = {
    expanded: { width: "16rem" },
    collapsed: { width: "6rem" }
  };

  const NavContent = ({ isMobile = false }) => (
    <div className="grid grid-cols-1 gap-6">
      <motion.div
        initial={false}
        animate={{ opacity: (!isCollapsed || isMobile) ? 1 : 0 }}
        className="text-white font-semibold text-3xl truncate"
      >
        DigitalDo
      </motion.div>

      {!isMobile && (
        <div className="py-3">
          <UserProfile 
            name={!isCollapsed ? getUser?.name : ""} 
            avatar={getUser?.photoURL} 
            isCollapsed={isCollapsed}
          />
        </div>
      )}

      {sideBarSections.map(item => (
        <motion.div
          key={item.id}
          className={`flex items-center gap-x-3 p-3 rounded-xl cursor-pointer transition-colors duration-200
            ${isActiveSection === item.id ? 
              'bg-white/20 shadow-lg backdrop-blur-sm' : 
              'hover:bg-white/10'}`}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={()=>{setIsActiveSection(item?.id)}}
        >
          <div className={`text-white ${isActiveSection === item.id ? 'opacity-100' : 'opacity-70'}`}>
            {item.icon}
          </div>
          {(!isCollapsed || isMobile) && (
            <motion.span 
              initial={false}
              animate={{ opacity: 1 }}
              className={`text-white ${isActiveSection === item.id ? 'font-medium' : 'opacity-70'}`}
             
            >
              {item?.label}
            </motion.span>
          )}
        </motion.div>
      ))}
    </div>
  );

  useGetCourses()

  return (
    <>
      <motion.div
        className="hidden z-10 md:block fixed h-screen bg-gradient-to-br from-violet-800 via-indigo-800 to-purple-800 overflow-hidden shadow-2xl"
        initial="expanded"
        animate={isCollapsed ? "collapsed" : "expanded"}
        variants={sidebarVariants}
      >
        <div className="p-6 h-full relative">
          <motion.button
            onClick={toggleSidebar}
            className={`absolute ${isCollapsed ? "-left-3" : "-right-3" }  top-10 bg-white/10 p-2 rounded-full backdrop-blur-sm hover:bg-white/20`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <motion.div
              animate={{ rotate: isCollapsed ? 0 : 180 }}
              className="text-white"
            >
              →
            </motion.div>
          </motion.button>
          
          <NavContent />
          
          <motion.div 
            className="absolute bottom-6 left-6 flex items-center gap-x-3 cursor-pointer p-3 rounded-xl hover:bg-white/10"
            whileHover={{ scale: 1.05 }}
          >
            <Settings className="w-5 h-5 text-white opacity-70" />
            {!isCollapsed && <span className="text-white opacity-70">Settings</span>}
          </motion.div>
        </div>
      </motion.div>
      

      <motion.div 
        className="md:hidden fixed bottom-0 left-0 right-0 bg-white/80 backdrop-blur-md border-t border-gray-200 z-50 px-6 py-4"
        initial={{ y: "100%" }}
        animate={{ y: 0 }}
        transition={{ type: "spring", bounce: 0 }}
        
      >
        {/* <div className='fixed bottom-[3.8rem] z-10 left-[42%] sm:left-[47%] bg-violet-700 p-6  rounded-full'>
        <div><Home className="w-6 h-6" /></div>
      </div> */}
        <div className="flex justify-between items-center">
          {sideBarSections.map(item => (
            <motion.div
              key={item.id}
              whileTap={{ scale: 0.95 }}
              className={`flex flex-col items-center gap-y-1 p-2 rounded-lg
                ${isActiveSection === item.id ? 
                  'bg-violet-100 text-violet-800' : 
                  'text-gray-600'}`}
                  onClick={()=>{setIsActiveSection(item?.id)}}
            >
              {item.icon}
              <span className="text-xs font-medium">{item.label}</span>
            </motion.div>
          ))}
          <motion.div
            whileTap={{ scale: 0.95 }}
            className="flex flex-col items-center gap-y-1 p-2 rounded-lg"
          >
            <User className=" w-6 h-6 text-gray-600" />
            <span className="text-xs font-medium text-gray-600 w-full ">Profile</span>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

export default SideBarLMS;