import { collection, getDocs,query, orderBy } from "firebase/firestore";
import { auth, firebaseAuth, firebaseDb } from "../../utils/firebase";
import { useDispatch, useSelector } from "react-redux";
import { addCourse, addCourseLecture, addCourseModule } from "../reduxSlices/courseSlice";
import { useEffect, useState } from "react";
import { addMyCourse, addPurchasedCourse, addTotalUser, addUser } from "../reduxSlices/userSlice";



const useGetCourses = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const firebaseGet = await getDocs(collection(firebaseDb, "course"));

        // Iterate through documents and include id
        firebaseGet.forEach((doc) => {
          const courseData = {
            id: doc.id, // Add the document ID
            ...doc.data(), // Spread the document data
          };

          // Dispatch the course data with ID to Redux
         
          dispatch(addCourse(courseData));
        });
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourse();
  }, [dispatch]); // Include dispatch as a dependency
};

export const useGetUser = (user) => {
  const dispatch = useDispatch()
  

  dispatch(addUser(user))
  

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     try {
        

  //       const userData =  auth.currentUser
  //       console.log("userData"+userData)

  //       dispatch(addUser(userData));
       

      
  //     } catch (error) {
  //       console.error("Error fetching user:", error);
  //     }
  //   };

  //   fetchUser();
  // }, [dispatch]); 
};

export const useGetTotalUser = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const firebaseGet = await getDocs(collection(firebaseDb, "user"));

        // Iterate through documents and include id
        firebaseGet.forEach((doc) => {
          const userData = {
            id: doc.id, // Add the document ID
            ...doc.data(), // Spread the document data
          };

          // Dispatch the course data with ID to Redux
         
          dispatch(addTotalUser(userData));
        });
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourse();
  }, [dispatch]); // Include dispatch as a dependency
};


export const useGetCourseModule = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        // Create a query with orderBy
        const modulesQuery = query(
          collection(firebaseDb, "module"),
          orderBy("order", "asc") // Sort by order field in ascending order
        );

        const firebaseGet = await getDocs(modulesQuery);

        // Create an array to store all modules
        const modules = [];

        // Iterate through documents and include id
        firebaseGet.forEach((doc) => {
          const moduleData = {
            id: doc.id,
            ...doc.data(),
          };
          modules.push(moduleData);
        });

        // Option 1: Dispatch all modules at once (recommended)
        dispatch(addCourseModule(modules));

        // Option 2: If you need to dispatch individually
        // modules.forEach(moduleData => {
        //   dispatch(addCourseModule(moduleData));
        // });

      } catch (error) {
        console.error("Error fetching modules:", error);
      }
    };

    fetchCourse();
  }, [dispatch]);
};


export const useGetCourseLecture = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLectures = async () => {
      try {
        // Create a query with orderBy
        const lecturesQuery = query(
          collection(firebaseDb, "lecture"),
          orderBy("order", "asc") // Sort by order field in ascending order
        );

        const firebaseGet = await getDocs(lecturesQuery);

        // Create an array to store all lectures
        const lectures = [];

        // Iterate through documents and include id
        firebaseGet.forEach((doc) => {
          const lectureData = {
            id: doc.id,
            ...doc.data(),
          };
          lectures.push(lectureData);
        });

        // Option 1: Dispatch all lectures at once (recommended)
        dispatch(addCourseLecture(lectures));

        // Option 2: If you need to dispatch individually
        // lectures.forEach(lectureData => {
        //   dispatch(addCourseLecture(lectureData));
        // });

      } catch (error) {
        console.error("Error fetching lectures:", error);
      }
    };

    fetchLectures();
  }, [dispatch]);
};


export const useGetMyCourse = () => {
  const dispatch = useDispatch();
  const GetCourses = useSelector((state) => state.course.courses);
  const [filteredMyCourse, setFilteredMyCourse] = useState([]);

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const firebaseGet = await getDocs(collection(firebaseDb, "payments"));
        const myCoursesArray = [];

        firebaseGet.forEach((doc) => {
          const userData = {
            id: doc.id,
            ...doc.data(),
          };
          myCoursesArray.push(userData);
        });

        const currentUserID = firebaseAuth.currentUser.uid;
        const filteredPurchaseCourse = myCoursesArray.filter(course => course.userId === currentUserID);

        // Dispatch the course data with ID to Redux
        dispatch(addPurchasedCourse(filteredPurchaseCourse));

        // Filter courses based on purchased course IDs
        const purchasedCourseIDs = filteredPurchaseCourse.map(course => course.courseID);
        const GetFilteredMyCourse = GetCourses.filter(course => purchasedCourseIDs.includes(course.id));

        setFilteredMyCourse(GetFilteredMyCourse);
        dispatch(addMyCourse(GetFilteredMyCourse))
        console.log(GetFilteredMyCourse);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourse();
  }, [dispatch, GetCourses]); // Include only dispatch and GetCourses as dependencies
};

export default useGetCourses;
