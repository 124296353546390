import React from 'react'

const UserProfle = ({name,avatar,isCollapsed}) => {
 

  return (
    <div>
        <div className='flex items-center gap-x-3'>
            <div><img className={`rounded-full ${isCollapsed ? "w-16 ml-2 scale-125" : "w-11"}`} src={avatar} alt='avatar'/></div>
            <div className='text-white text-lg'>{name}</div>
            
            


        </div>
        <hr className='opacity-30 mt-4'/>
      
    </div>
  )
}

export default UserProfle
