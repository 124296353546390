import React, { useEffect, useState } from "react";
import SideBarLMS from "./SideBarLMS";
import { useGetUser } from "../utils/firebaseDataFetch/useGetCourses";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { auth, firebaseAuth } from "../utils/firebase";
import { addUser } from "../utils/reduxSlices/userSlice";
import useUserFromLocal from "../utils/useUserFromLocal";
import Overview from "./Overview";
import MyCourses from "./subComponents/MyCourses";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from 'firebase/auth';


const MainLMS = () => {
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        console.log('User authenticated:', user);
        // Additional actions for authenticated user
      } else {
        console.log('No user authenticated');
        // Additional actions for no user
      }
    });

    return () => unsubscribe();
  }, []);





    // console.log(firebaseAuth.currentUser)
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isActiveSection, setIsActiveSection] = useState("overview");
  const navigate= useNavigate()

  // const user = firebaseAuth.currentUser
  const user= useUserFromLocal()


  // useEffect(() => {
  //   if (!firebaseAuth.currentUser) {
     
  //     navigate("/auth");
  //   }
  // }, [firebaseAuth, navigate]);
 

  const Dashboard = () => {
    switch (isActiveSection) {
      case "overview":
        return <Overview />;

      case "courses":
        return <MyCourses />;

      default:
        return null
    }
  };
  


  return (
    <div>
      <SideBarLMS
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        isActiveSection={isActiveSection}
        setIsActiveSection={setIsActiveSection}
      />
      <div className={`${isCollapsed ? "sm:pl-[6%]" : "sm:pl-[18%]"}`}>
        <Dashboard/>
      </div>
    </div>
  );
};

export default MainLMS;
