import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import {
  EnvelopeIcon,
  LockClosedIcon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { firebaseUploadUser } from "../../utils/firebaseDataFetch/uploadCourse";
import { Rocket, BookOpen, Sparkles } from "lucide-react";
import { useGetUser } from "../../utils/firebaseDataFetch/useGetCourses";
import { addUser } from "../../utils/reduxSlices/userSlice";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const AuthPage = ({ onClose, isButtonLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  const [isLogin, setIsLogin] = useState(isButtonLogin ? true : false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);


  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const containerVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        type: "spring",
        stiffness: 100,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      transition: { duration: 0.3 },
    },
  };

  const handleUserData = async(user) => {
    const userData = {
      userId: user.uid,
      email: user.email,
      name: user.displayName || formData.name,
      photoURL: user.photoURL || null,
    };

    // Assuming you have a custom hook for adding user data
    // console.log(userData)

    

   
    
    await firebaseUploadUser(userData).then(() => { 
      // console.log("Data being stored:", userData); // Debug log
      
      try {
        const dataToStore = JSON.stringify(userData);
        // console.log("Stringified data:", dataToStore); // Debug log
        localStorage.setItem("getUserData", dataToStore);
        
        // Verify it was stored correctly
        const storedData = localStorage.getItem("getUserData");
        // console.log("Verification - stored data:", storedData); // Debug log
        
        dispatch(addUser(userData));
        navigate("/");
      } catch (error) {
        console.error("Error storing user data:", error);
      }
    });
 
  };

  const firebaseErrorCheck=(error)=>{
    // console.log(error)
    switch (error) {
      case "Firebase: Error (auth/email-already-in-use).":
        setError("Email already in use");
        break;
      case "Firebase: Password should be at least 6 characters (auth/weak-password).":
        setError("Password should be at least 6 characters");
        break;
      case "Firebase: Error (auth/invalid-email).":
        setError("Invalid email format");
        break;
      case "Firebase: Error (auth/user-not-found).":
        setError("User not found. Please check your email or sign up.");
        break;
      case "Firebase: Error (auth/wrong-password).":
        setError("Incorrect password. Please try again.");
        break;
      case "Firebase: Error (auth/too-many-requests).":
        setError("Too many failed attempts. Please try again later.");
        break;
      case "Firebase: Error (auth/network-request-failed).":
        setError("Network error. Please check your internet connection.");
        break;
      case "Firebase: Error (auth/operation-not-allowed).":
        setError("This operation is not allowed. Please contact support.");
        break;
      case "Firebase: Error (auth/requires-recent-login).":
        setError("Please log in again to perform this operation.");
        break;
      case "Firebase: Error (auth/account-exists-with-different-credential).":
        setError("An account already exists with a different login method. Try using that method to sign in.");
        break;
        case "Firebase: Error (auth/missing-password).":
        setError("Missing Password");
        break;
        case "Firebase: Error (auth/popup-closed-by-user).":
          setError("Popup Closed");
          break;
      default:
        
        setError(error.message);
    }

  }



  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      const result = await signInWithPopup(auth, googleProvider);
      handleUserData(result.user);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSignIn = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const result = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      handleUserData(result.user);
    } catch (error) {
      // setError(error.message);
      firebaseErrorCheck(error.message)
    } finally {
      setLoading(false);
    }
  };

  const passwordValidation = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return regex.test(password);
  };


  
  

  const handleEmailSignUp = async (e) => {
    e.preventDefault();
    if (!passwordValidation(formData.password)) {
      setError("Password must be at least 6 characters, include uppercase, lowercase, a number, and a special character.");
      return;
    }

    try {
      setLoading(true);
      const result = await createUserWithEmailAndPassword(
        auth,
        formData?.email,
        formData?.password
      );
      handleUserData(result.user);
      // console.log(result.user)
    } catch (error) {
     

      firebaseErrorCheck(error.message)



     
      // if(error.message=="Firebase: Error (auth/email-already-in-use)."){
      //   setError("Email already in use")
      // }else{
      //   setError(error.message);
      // }
      
    } finally {
      setLoading(false);
    }
  };

  const pageVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
  };

  const formVariants = {
    initial: { x: 50, opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: -50, opacity: 0 }
  };

return (
    <motion.div 
      className="min-h-screen flex flex-col md:flex-row"
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      {/* Left Section */}
      <div className="bg-violet-600 hidden sm:block text-white p-8 md:w-1/2 md:flex flex-col justify-center">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="max-w-xl mx-auto"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            {isLogin ? "Welcome Back!" : "Begin Your Learning Journey"}
          </h1>
          <p className="text-lg md:text-xl mb-8 text-violet-100">
            {isLogin 
              ? "Continue your journey of growth and discovery with us"
              : "Join thousands of learners worldwide and unlock your potential today"}
          </p>
          <div className="space-y-6">
            <Feature 
              Icon={Rocket} 
              title="Personalized Learning Path"
              description="Tailored courses that adapt to your pace and style"
            />
            <Feature 
              Icon={BookOpen} 
              title="Expert-Led Content"
              description="Learn from industry professionals and thought leaders"
            />
            <Feature 
              Icon={Sparkles} 
              title="Interactive Experience"
              description="Engage with practical exercises and real-world projects"
            />
          </div>
        </motion.div>
      </div>

      {/* Right Section */}
      <div className="p-8 md:w-1/2 flex items-center  justify-center bg-gray-50">
        <AnimatePresence mode="wait">
          <motion.div
            key={isLogin ? "login" : "signup"}
            variants={formVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.3 }}
            className="w-full max-w-md space-y-8"
          >
            {/* <div className="text-center">
              <h2 className="text-3xl font-bold text-gray-900">
                {isLogin ? "Sign in to your account" : "Create your account"}
              </h2>
            </div> */}

            <form className="space-y-6" onSubmit={isLogin ? handleEmailSignIn : handleEmailSignUp}>
              {/* {!isLogin && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <div className="mt-1 relative ">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                      <UserIcon className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      name="name"
                      required
                      className="pl-10 block w-full py-2 rounded-lg border-gray-300 shadow-sm focus:ring-violet-500 focus:border-violet-500"
                      placeholder="Full Name"
                    />
                  </div>
                </div>
              )} */}

              {/* <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <div className="mt-1 relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                    <EnvelopeIcon className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    name="email"
                    required
                    className="pl-10 block w-full py-2 rounded-lg border-gray-300 shadow-sm focus:ring-violet-500 focus:border-violet-500"
                    placeholder="Email address"
                  />
                </div>
              </div> */}

              {/* <div>
                <label className="block text-sm font-medium text-gray-700">Password</label>
                <div className="mt-1 relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                    <LockClosedIcon className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="password"
                    name="password"
                    required
                    className="pl-10 block w-full py-2 rounded-lg border-gray-300 shadow-sm focus:ring-violet-500 focus:border-violet-500"
                    placeholder="Password"
                  />
                </div>
              </div> */}
              {/* <img src="/logo.svg" alt="logo"/> */}
              <DotLottieReact
      src="https://lottie.host/515e6673-d703-4227-aa48-37cc4caa40eb/M5wWZa3rnp.lottie"
      loop
      autoplay
    />

              {error && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-sm text-red-600"
                >
                  {error}
                </motion.div>
              )}

              {/* <button
                type="submit"
                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-violet-600 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
              >
                {loading ? "Loading..." : isLogin ? "Sign in" : "Sign up"}
              </button> */}
              

              <button
                type="button"
                onClick={handleGoogleSignIn}
                className="w-full flex items-center justify-center gap-3 py-3 px-4 border border-gray-300 rounded-lg shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                <svg className="h-5 w-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                  <path d="M12 23c2.97 0 5.46-1 7.28-2.69l-3.57-2.77c-.99.69-2.26 1.1-3.71 1.1-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C4.09 20.53 7.79 23 12 23z" fill="#34A853"/>
                  <path d="M5.84 14.11c-.22-.69-.35-1.43-.35-2.11s.13-1.42.35-2.11V7.05H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.95l2.66-2.84z" fill="#FBBC05"/>
                  <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.46 2.09 14.97 1 12 1 7.79 1 4.09 3.47 2.18 7.05l3.66 2.84c.87-2.6 3.3-4.51 6.16-4.51z" fill="#EA4335"/>
                </svg>
                Continue with Google
              </button>

              {/* <div className="text-center">
                <button
                  type="button"
                  onClick={() => {setIsLogin(!isLogin); setError("");}}
                  className="text-sm text-violet-600 hover:text-violet-500"
                >
                  {isLogin ? "Need an account? Sign up" : "Already have an account? Sign in"}
                </button>
              </div> */}
            </form>
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const Feature = ({ Icon, title, description }) => (
  <motion.div 
    className="flex items-center space-x-4"
    whileHover={{ x: 10 }}
  >
    <div className="flex-shrink-0">
      <Icon className="h-6 w-6" />
    </div>
    <div>
      <h3 className="font-semibold">{title}</h3>
      <p className="text-violet-100">{description}</p>
    </div>
  </motion.div>
);

export default AuthPage;