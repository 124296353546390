import React, { useState, useMemo, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  Book,
  FileText,
  MessageCircle,
  Award,
  ChevronRight,
  ChevronDown,
  CheckCircle2,
  ArrowLeft,
  ArrowRight,
  Users,
  Play,
  XCircle,
} from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import useCourseProgress from "./utils/useCourseProgress";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useGetFullCourseStructure from "./utils/useGetFullCourseStructure";
import {
  useGetCourseLecture,
  useGetCourseModule,
} from "../utils/firebaseDataFetch/useGetCourses";
import { firebaseAuth, firebaseDb } from "../utils/firebase";
import { useSelector } from "react-redux";

// Updated Mock Data with status and expanded property for modules
const testMockData = [
  {
    module_title: "Basic Tools",
    order: "1",
    isExpanded: true,
    icon: <Book className="w-5 h-5 text-violet-600" />,
    lectures: [
      {
        order: "1",
        videoLink: "https://ssdsds.com",
        lecture_title: "Introduction Basics",
        status: false,
        notes: [
          {
            title: "Shortcut PDF",
            url: "https://www.test.com",
          },
          {
            title: "Software Links",
            url: "https://www.test.com",
          },
        ],
      },
      {
        order: "2",
        videoLink: "https://ssdsds.com",
        lecture_title: "Basic Tools",
        status: false,
        notes: [
          {
            title: "Shortcut PDF",
            url: "https://www.test.com",
          },
          {
            title: "Software Links",
            url: "https://www.test.com",
          },
        ],
      },
    ],
  },
  {
    module_title: "Introduction",
    order: "2",
    isExpanded: true,
    icon: <Users className="w-5 h-5 text-violet-600" />,
    lectures: [
      {
        order: "1",
        videoLink: "https://ssdsds.com",
        lecture_title: "Introduction Basics",
        status: false,
        notes: [
          {
            title: "Shortcut PDF",
            url: "https://www.test.com",
          },
          {
            title: "Software Links",
            url: "https://www.test.com",
          },
        ],
      },
      {
        order: "2",
        videoLink: "https://ssdsds.com",
        lecture_title: "Basic Tools",
        status: false,
        notes: [
          {
            title: "Shortcut PDF",
            url: "https://www.test.com",
          },
          {
            title: "Software Links",
            url: "https://www.test.com",
          },
        ],
      },
    ],
  },
];

// Custom hook for calculating course progress

const CoursePage = () => {
  useGetCourseModule();
  useGetCourseLecture();

  const { courseId, courseTitle } = useParams();
  const courseStructure = useGetFullCourseStructure(courseId);

  

  const [mockData, setMockData] = useState(courseStructure);
  const [selectedModule, setSelectedModule] = useState(courseStructure[0]);
  const [selectedLecture, setSelectedLecture] = useState(
    selectedModule?.lectures[0] 
  );
  const courseProgress = useCourseProgress(mockData);
  const [activeSection, setActiveSection] = useState("overview");
  const [isRightSectionCollapsed, setIsRightSectionCollapsed] = useState(false);
  const [completionPopup, setCompletionPopup] = useState(null);

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate= useNavigate()

  const getUserData= useSelector(state=>state.users?.user)
  const {userId}=getUserData

  // useEffect(() => {
  //   if (!firebaseAuth.currentUser) {
     
  //     navigate("/auth");
  //   }
  // }, [firebaseAuth, navigate]);



  // useEffect(() => {
  //   const checkCourseAccess = async () => {
  //     try {
  //       // Current user's ID from Firebase Auth
  //       const currentUserId = userId;

       

  //       // Query payments collection for this user and course
  //       const paymentsRef = collection(firebaseDb, "payments");
  //       const q = query(
  //         paymentsRef,
  //         where("userId", "==", currentUserId),
  //         where("courseID", "==", courseId)
  //       );

  //       const querySnapshot = await getDocs(q);
   

  //       // Set authorization based on payment existence
  //       setIsAuthorized(!querySnapshot.empty);
  //     } catch (error) {
  //       console.error("Access check failed", error);
  //       setIsAuthorized(false);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   checkCourseAccess();
    

  // }, [courseId,userId]);
  

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }
  // if (!isAuthorized) {
  //   const learnDomain =
  //     process.env.NODE_ENV === "production"
  //       ? `https://${window.location.hostname.replace("learn.","")}`
  //       : `http://${window.location.hostname.replace("learn.","")}:3000`;
  
  //   window.location.replace(`${learnDomain}/courseDetails/${courseId}`);
  //   return null; // Prevent rendering while redirecting
  // }


  // console.log(useParams())

  // Calculate course progress

  // Toggle module expansion
  
  
  
  
  
  const toggleModuleExpansion = (moduleIndex) => {
    const updatedData = [...mockData];
    updatedData[moduleIndex].isExpanded = !updatedData[moduleIndex].isExpanded;
    setMockData(updatedData);
  };

  // Handle lecture completion confirmation
  const handleLectureCompletion = (lecture) => {
    setCompletionPopup({
      lecture: lecture,
      message:
        "Awesome job! Are you sure you want to mark this chapter as complete?",
    });
  };

  // Confirm lecture completion
  const confirmLectureCompletion = () => {
    if (completionPopup) {
      const updatedMockData = mockData.map((module) => ({
        ...module,
        lectures: module.lectures.map((lecture) =>
          lecture === completionPopup.lecture
            ? { ...lecture, status: true }
            : lecture
        ),
      }));

      setMockData(updatedMockData);
      setSelectedLecture({ ...completionPopup.lecture, status: true });
      setCompletionPopup(null);
    }
  };

  // Cancel lecture completion
  const cancelLectureCompletion = () => {
    setCompletionPopup(null);
  };

  // Sections for the bottom navigation
  const sections = [
    {
      id: "overview",
      title: "Overview",
      icon: <Book className="w-5 h-5" />,
    },
    {
      id: "discuss",
      title: "Discuss",
      icon: <MessageCircle className="w-5 h-5" />,
    },
    {
      id: "notes",
      title: "Notes",
      icon: <FileText className="w-5 h-5" />,
    },
    {
      id: "certificates",
      title: "Certificates",
      icon: <Award className="w-5 h-5" />,
    },
  ];

  // Render section content
  const renderSectionContent = () => {
    switch (activeSection) {
      case "overview":
        return (
          <div className="p-4 bg-gray-50 rounded-lg">
            <h3 className="text-lg font-semibold text-violet-700">
              Lecture Overview
            </h3>
            <p className="mt-2 text-gray-600">
              {selectedLecture?.lecture_title} provides a comprehensive
              introduction to the core concepts.
            </p>
          </div>
        );
      case "discuss":
        return (
          <div className="p-4 bg-gray-50 rounded-lg">
            <h3 className="text-lg font-semibold text-violet-700">
              Discussion Forum
            </h3>
            <p className="mt-2 text-gray-600">
              Connect with your peers, share insights, and learn together.
            </p>
            <button className="mt-4 flex items-center space-x-2 bg-violet-600 text-white px-4 py-2 rounded-lg hover:bg-violet-700">
              <Users className="w-5 h-5" />
              <span>Join Community</span>
            </button>
          </div>
        );
      case "notes":
        return (
          <div className="p-4 bg-gray-50 rounded-lg">
            <h3 className="text-lg font-semibold text-violet-700">
              Lecture Notes
            </h3>
            <div className="flex flex-wrap gap-2 mt-2">
              {selectedLecture.notes.map((note, index) => (
                <a
                  key={index}
                  href={note.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-3 py-1 border border-violet-500 text-violet-600 rounded-full hover:bg-violet-100 transition-colors flex items-center space-x-2"
                >
                  <FontAwesomeIcon icon={faDownload} />
                  <span>{note.title}</span>
                </a>
              ))}
            </div>
          </div>
        );
      case "certificates":
        return (
          <div className="p-4 bg-gray-50 rounded-lg">
            <h3 className="text-lg font-semibold text-violet-700">
              Course Certificates
            </h3>
            <p className="mt-2 text-gray-600">
              Complete all lectures to unlock your course certificate.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-100 relative">
      {/* Collapse/Expand Button for Desktop */}
      {!isRightSectionCollapsed && (
        <button
          onClick={() => setIsRightSectionCollapsed(true)}
          className="hidden lg:block absolute top-4 right-4 z-50 bg-white shadow-md rounded-full p-2"
        >
          <ArrowRight className="w-6 h-6 text-violet-600" />
        </button>
      )}

      {isRightSectionCollapsed && (
        <button
          onClick={() => setIsRightSectionCollapsed(false)}
          className="hidden lg:block absolute top-4 right-4 z-50 bg-white shadow-md rounded-full p-2"
        >
          <ArrowLeft className="w-6 h-6 text-violet-600" />
        </button>
      )}

      {/* Completion Confirmation Popup */}
      {completionPopup && (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]"
        >
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full">
            <div className="flex items-center justify-center mb-4">
              <CheckCircle2 className="w-16 h-16 text-green-500" />
            </div>
            <h3 className="text-xl font-bold text-center mb-4 text-violet-900">
              Lecture Completion
            </h3>
            <p className="text-center text-gray-600 mb-6">
              {completionPopup.message}
            </p>
            <div className="flex justify-center space-x-4">
              <motion.button
                onClick={cancelLectureCompletion}
                whileTap={{ scale: 0.95 }}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors flex items-center space-x-2"
              >
                <XCircle className="w-5 h-5" />
                <span>Cancel</span>
              </motion.button>
              <motion.button
                onClick={confirmLectureCompletion}
                whileTap={{ scale: 0.95 }}
                className="px-4 py-2 bg-violet-600 text-white rounded-lg hover:bg-violet-700 transition-colors flex items-center space-x-2"
              >
                <CheckCircle2 className="w-5 h-5" />
                <span>Confirm</span>
              </motion.button>
            </div>
          </div>
        </motion.div>
      )}

      {/* Left Section - 70% width */}
      <div
        className={`
        w-full 
        ${isRightSectionCollapsed ? "lg:w-full" : "lg:w-[70%]"} 
        p-4 lg:p-8 transition-all duration-300
      `}
      >
        {/* Course Title */}
        <h1 className="text-2xl lg:text-3xl font-bold text-violet-900 mb-4">
          {courseTitle}
        </h1>

        {/* Video Player with Play Icon */}
        <div className="w-full aspect-video bg-black mb-4 rounded-lg flex items-center justify-center">
          <Play className="w-16 h-16 text-white" />
        </div>

        {/* Section Navigation */}
        <div className="flex space-x-2 overflow-x-scroll no-scrollbar  sm:overflow-auto lg:space-x-4 mb-4">
          {sections.map((section) => (
            <motion.button
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              className={`
                flex items-center space-x-2 px-3 py-2 rounded-full transition-all
                ${
                  activeSection === section.id
                    ? "bg-violet-600 text-white"
                    : "bg-gray-200 text-gray-700 hover:bg-violet-100"
                }
              `}
              whileTap={{ scale: 0.95 }}
            >
              {section.icon}
              <span className="text-sm font-medium">{section.title}</span>
            </motion.button>
          ))}
        </div>

        {/* Section Content */}
        <AnimatePresence mode="wait">
          <motion.div
            key={activeSection}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
          >
            {renderSectionContent()}
          </motion.div>
        </AnimatePresence>

        {/* Feedback Button */}
        <motion.button
          className="w-full mt-4 bg-violet-600 text-white py-3 rounded-lg hover:bg-violet-700 transition-colors"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Provide Lecture Feedback
        </motion.button>
      </div>

      {/* Right Section - 30% width */}
      {!isRightSectionCollapsed && (
        <div className="w-full lg:w-[30%] p-4  lg:p-8 bg-white shadow-lg overflow-y-auto max-h-screen sticky top-0">
          {/* Progress Bar */}
          <div className="mb-4 pt-12">
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-medium text-gray-600">
                Course Progress
              </span>
              <span className="text-sm font-bold text-violet-700">
                {courseProgress}%
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div
                className="bg-violet-600 h-2.5 rounded-full"
                style={{ width: `${courseProgress}%` }}
              ></div>
            </div>
          </div>

          {/* Course Modules */}
          <div className="no-scrollbar overflow-y-auto  max-h-[70vh]">
            <h2 className="text-lg font-bold text-violet-900 mb-4">
              Course Modules
            </h2>
            {mockData.map((module, moduleIndex) => (
              <div key={moduleIndex} className="mb-4">
                <motion.div
                  className="flex items-center justify-between mb-2 cursor-pointer"
                  onClick={() => toggleModuleExpansion(moduleIndex)}
                >
                  <div className="flex items-center space-x-2">
                    {module.icon}
                    <h3 className="text-md font-semibold text-gray-800">
                      {module.module_title}
                    </h3>
                  </div>
                  {module.isExpanded ? (
                    <ChevronDown className="w-5 h-5 text-gray-500" />
                  ) : (
                    <ChevronRight className="w-5 h-5 text-gray-500" />
                  )}
                </motion.div>
                <AnimatePresence>
                  {module.isExpanded && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      {module?.lectures.map((lecture, lectureIndex) => (
                        <motion.div
                          key={lectureIndex}
                          className={`
                            px-3 py-2 rounded-lg cursor-pointer transition-colors flex justify-between items-center
                            ${
                              selectedLecture === lecture
                                ? "bg-violet-100 text-violet-800"
                                : "hover:bg-gray-100"
                            }
                          `}
                          whileTap={{ scale: 0.98 }}
                        >
                          <div
                            onClick={() => {
                              setSelectedModule(module);
                              setSelectedLecture(lecture);
                            }}
                            className="flex-grow"
                          >
                            <span className="text-sm">
                              {lecture.lecture_title}
                            </span>
                          </div>
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleLectureCompletion(lecture);
                            }}
                            className="ml-2"
                          >
                            <CheckCircle2
                              className={`
                                w-5 h-5 
                                ${
                                  lecture.status
                                    ? "text-green-500"
                                    : "text-gray-300"
                                }
                              `}
                            />
                          </button>
                        </motion.div>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CoursePage;
