import React, { useEffect, useState } from "react";
import { RAZORPAY_KEY_ID } from "../constants";
import { companyDetails } from "../mockdata";
import { motion } from "framer-motion";
import { ShoppingCart } from "lucide-react";
import { useSelector } from "react-redux";
import { firebaseAuth, firebaseDb } from "../firebase";
import { addDoc, collection, Timestamp  } from "firebase/firestore"
import AuthComponent from "../../components/subComponents/AuthComponent";

const PaymentButton = ({ amount,courseDetails,isUserLoggedIn,setIsUserLoggedIn,setIsOpenSuccessPayment }) => {
  const [isRazorpayLoaded, setIsRazorpayLoaded] = useState(false);


  const {courseId,id}= courseDetails

  



  useEffect(() => {
    // Dynamically load the Razorpay script
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.onload = () => setIsRazorpayLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const userData = useSelector((state) => state.users.user);
  


  const handlePayment = async () => {

    if(!firebaseAuth.currentUser?.uid){
      setIsUserLoggedIn(false)
      return

      
    }
    if (!isRazorpayLoaded) {
      alert('Razorpay script is still loading. Please try again.');
      return;
    }
    

  

    try {
      // Initialize Razorpay with your key ID
      const rzp = new window.Razorpay({
        key: RAZORPAY_KEY_ID, // Replace with your Razorpay key ID
        amount: amount * 100, // Amount should be in paise (multiply by 100)
        currency: "INR",
        name: companyDetails?.name,
        description: "Course Purchase",
        image: "/logo.svg", // Optional
        handler: async function (response) {
          const paymentId = response.razorpay_payment_id;
          const orderId = response.razorpay_order_id;
          const signature = response.razorpay_signature;
          // console.log(paymentId,orderId)

          // Verify the payment signature here if needed

          // Update Firebase Firestore or Realtime Database
          try {
            await addDoc(collection(firebaseDb, "payments"), {
              userId: firebaseAuth.currentUser.uid, // Assuming user is authenticated
              name:firebaseAuth.currentUser.displayName,
              
              amount: Number(amount),
              paymentId: paymentId,
              courseCode:courseId,
              courseID:id,
              
              timestamp: String(new Date()),
            });
            setIsOpenSuccessPayment(true)

            // alert("Payment successful and recorded!");
          } catch (error) {
            console.error("Error recording payment:", error);
            alert("Payment successful but failed to record.");
          }
        },
        prefill: {
          name: firebaseAuth.currentUser.displayName || "Name",
          email: firebaseAuth.currentUser.email || "Email",
          contact:""

          
        },
        notes: {
          address: "Note for the transaction",
        },
        theme: {
          color: "#4F45E4",
        },
      });

      rzp.open();
    } catch (error) {
      console.error("Error occurred:", error);
      alert("An error occurred during payment.");
    }
  };

  return (
    <>
    
    <motion.button
      onClick={handlePayment}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className="w-full bg-white text-indigo-600 py-3 rounded-lg font-semibold hover:bg-indigo-50 transition flex items-center justify-center gap-2"
      disabled={!isRazorpayLoaded}
    >
      <ShoppingCart className="w-5 h-5" />
      Enroll Now
    </motion.button>
    </>
  );
};

export default PaymentButton;