import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const DeleteModal = ({ isOpen, onClose, onConfirm, courseName }) => {
   
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          {/* Modal Wrapper */}
          <motion.div
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-11/12 max-w-md p-6"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.3 }}
          >
            {/* Modal Header */}
            <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
              Confirm Deletion
            </h2>
            <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
              Are you sure you want to delete <br/>{" "}
              <span className="font-bold">{courseName}</span>? 
            </p>

            {/* Modal Actions */}
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={onConfirm}
                className="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default DeleteModal;
