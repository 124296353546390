import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  Award,
  BookOpen,
  Calendar,
  Clock,
  Flame,
  Gift,
  GraduationCap,
  Layout,
  MessageSquare,
  Star,
  Trophy,
  TrendingUp,
  Bell
} from 'lucide-react';
import { useSelector } from 'react-redux';

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 }
};

const Overview = () => {
  const [quote, setQuote] = useState("Learning is a treasure that will follow its owner everywhere.");
  const userData = {
    name: "Alex",
    streak: 5,
    progress: 75,
    hoursSpent: 48,
    coursesCompleted: 3,
    nextBadgeProgress: 85
  };

  const getUser= useSelector((state)=>state.users.user )

  const announcements = [
    { id: 1, title: "New Python Course Launch!", date: "2025-01-15" },
    { id: 2, title: "Weekend Workshop: AI Basics", date: "2025-01-20" }
  ];

  const upcomingEvents = [
    { id: 1, title: "JavaScript Advanced Quiz", date: "2025-01-12" },
    { id: 2, title: "React Workshop", date: "2025-01-15" }
  ];

  return (
    <div className="p-6 bg-gray-50/30 min-h-screen pb-28">
      {/* Welcome Section with Glass Morphism */}
      <motion.div 
        {...fadeInUp} 
        className="mb-8 p-6 rounded-2xl bg-white/70 backdrop-blur-lg border border-violet-100 shadow-lg"
      >
        <h1 className="text-3xl font-bold bg-gradient-to-r from-violet-800 to-violet-600 bg-clip-text text-transparent mb-2">
          Welcome {getUser ? getUser?.name : userData?.name}!
        </h1>
        <p className="text-gray-600 italic">{quote}</p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Achievement Card */}
        <motion.div {...fadeInUp}>
          <div className="rounded-2xl bg-white p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-violet-50">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                <Award className="text-violet-600 mr-2" size={24} />
                Achievements
              </h2>
            </div>
            <div className="relative pt-4">
              <div className="w-full bg-violet-100 rounded-full h-3">
                <div 
                  className="bg-gradient-to-r from-violet-600 to-violet-400 h-3 rounded-full transition-all duration-500"
                  style={{ width: `${userData.nextBadgeProgress}%` }}
                />
              </div>
              <p className="mt-3 text-sm text-gray-600">
                {100 - userData.nextBadgeProgress}% more to Gold Badge
              </p>
            </div>
          </div>
        </motion.div>

        {/* Progress Card */}
        <motion.div {...fadeInUp}>
          <div className="rounded-2xl bg-white p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-violet-50">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                <TrendingUp className="text-violet-600 mr-2" size={24} />
                Progress
              </h2>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="text-center p-4 rounded-xl bg-violet-50">
                <Clock className="inline-block text-violet-600 mb-2" size={24} />
                <p className="text-2xl font-bold text-violet-800">{userData.hoursSpent}h</p>
                <p className="text-sm text-gray-600">Total Hours</p>
              </div>
              <div className="text-center p-4 rounded-xl bg-violet-50">
                <BookOpen className="inline-block text-violet-600 mb-2" size={24} />
                <p className="text-2xl font-bold text-violet-800">{userData.coursesCompleted}</p>
                <p className="text-sm text-gray-600">Completed</p>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Streak Card */}
        <motion.div {...fadeInUp}>
          <div className="rounded-2xl bg-white p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-violet-50">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                <Flame className="text-violet-600 mr-2" size={24} />
                Streak
              </h2>
            </div>
            <div className="text-center p-4 rounded-xl bg-violet-50">
              <p className="text-3xl font-bold text-violet-800 mb-2">{userData.streak} Days</p>
              <p className="text-gray-600">Keep up the momentum! 🔥</p>
            </div>
          </div>
        </motion.div>

        {/* Upcoming Events */}
        <motion.div {...fadeInUp} className="md:col-span-2">
          <div className="rounded-2xl bg-white p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-violet-50">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                <Calendar className="text-violet-600 mr-2" size={24} />
                Upcoming Events
              </h2>
            </div>
            <div className="space-y-3">
              {upcomingEvents.map(event => (
                <motion.div 
                  key={event.id}
                  className="p-4 rounded-xl bg-violet-50 hover:bg-violet-100 transition-colors duration-300"
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-center justify-between">
                    <span className="font-medium text-violet-800">{event.title}</span>
                    <span className="text-sm px-3 py-1 bg-white rounded-full text-violet-600">{event.date}</span>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>

        {/* Announcements */}
        <motion.div {...fadeInUp}>
          <div className="rounded-2xl bg-white p-6 shadow-lg hover:shadow-xl transition-all duration-300 border border-violet-50">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-bold text-gray-800 flex items-center">
                <Bell className="text-violet-600 mr-2" size={24} />
                Announcements
              </h2>
            </div>
            <div className="space-y-3">
              {announcements.map(announcement => (
                <motion.div 
                  key={announcement.id}
                  className="p-4 rounded-xl bg-violet-50 hover:bg-violet-100 transition-colors duration-300"
                  whileHover={{ scale: 1.02 }}
                >
                  <p className="font-medium text-violet-800">{announcement.title}</p>
                  <p className="text-sm text-violet-600 mt-1">{announcement.date}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Overview;