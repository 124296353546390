import { useSelector } from "react-redux";
import { useMemo } from "react";

const useGetFullCourseStructure = (courseId) => {
  const modules = useSelector((state) => state.course.modules);
  const lectures = useSelector((state) => state.course.lecture);

  const courseStructure = useMemo(() => {
    const filteredModules =
      modules?.filter((module) => module?.courseFID === courseId) || [];

    return filteredModules.map((module) => ({
      module_title: module?.module_title,
      order: module?.order,
      lectures: lectures
        .filter((lecture) => lecture?.course_doc_id === module?.id)
        .map((lecture) => ({
          lecture_title: lecture?.lecture_title,
          status: lecture?.status ? lecture?.status : false,
          isPreview: lecture?.isPreview ? lecture?.isPreview : false,
          videoLink:lecture?.videoLink,
          order:lecture?.order,
          notes:lecture?.notes_links



        })),
    }));
  }, [courseId, modules, lectures]);

  //   console.log(courseStructure)

  return courseStructure;
};

export default useGetFullCourseStructure;
