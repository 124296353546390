import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  
  Play,
  ShoppingCart,
  ChevronDown,
  Ticket,
  Video,
  BookOpen,
  Clock,
  
  Monitor,
  
  GraduationCap,
  Users,
  Tag,
} from "lucide-react";
import { useLocation, useParams } from "react-router-dom";
import PaymentButton from "../../utils/payment/PaymentButton";
import { firebaseAuth } from "../../utils/firebase";
import AuthComponent from "./AuthComponent";
import SuccessPopup from "../../lms/subComponents/SuccessPopup";
import useGetCourseStructure from "../../utils/useGetCourseStructure";
import { useGetCourseLecture, useGetCourseModule } from "../../utils/firebaseDataFetch/useGetCourses";
import { useSelector } from "react-redux";


const courseData = {
  id: "ui-ux-master-2024",
  title: "UI/UX Design Mastery 2024",
  shortDescription:
    "Master the complete UI/UX design workflow from research to final deliverables",
  description:
    "Dive deep into the world of UI/UX design with this comprehensive course. Learn industry-standard tools, design principles, and practical workflows that will help you create exceptional user experiences. Perfect for beginners and intermediate designers looking to level up their skills.",
  instructor: {
    name: "Adhil",
    bio: "Senior UX Designer with 10+ years of experience",
    avatar: "/api/placeholder/100/100",
  },
  details: {
    enrolledStudents: 1245,
    rating: 4.7,
    language: "Malayalam",
    price: 2999,
    originalPrice: 4999,
    totalDuration: "13h 30m",
    totalLectures: 26,
  },
  courseStructure: [
    {
      module: "Introduction to UI/UX",
      lectures: [
        { title: "What is UI/UX Design", duration: "45m", isPreview: true },
        { title: "Design Thinking Process", duration: "1h 15m" },
        { title: "User Experience Fundamentals", duration: "30m" },
      ],
    },
    {
      module: "Design Principles",
      lectures: [
        { title: "Color Theory", duration: "1h", isPreview: true },
        { title: "Typography Basics", duration: "45m" },
        { title: "Layout and Composition", duration: "1h 30m" },
        { title: "Visual Hierarchy", duration: "45m" },
      ],
    },
  ],
};

const CourseDetails = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [expandedModules, setExpandedModules] = useState(new Set([0]));
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [isApplying, setIsApplying] = useState(false);
    const [isUserLoggedIn,setIsUserLoggedIn]=useState(true)
    const [isOpenSuccessPayment,setIsOpenSuccessPayment]=useState(false)

  // const location = useLocation();
  // const { course } = location.state || {};


  const [course, setCourses] = useState(null);
  const { uniqueCourseId } = useParams();

  const GetCurses = useSelector((state) => state.course.courses);
  
  useEffect(() => {
    const filteredCourse = GetCurses.find((data) => data?.id === uniqueCourseId);
    
    if (filteredCourse) {
      setCourses(filteredCourse);
    }
  }, [GetCurses, uniqueCourseId]);



  






  const courseStructure = useGetCourseStructure(uniqueCourseId)
  // console.log(courseStructure)

  



  // console.log(firebaseAuth.currentUser.uid)

  // Mock coupon validation
  const validateCoupon = async (code) => {
    // Simulating API call
    return new Promise((resolve) => {
      setTimeout(() => {
        if (code === "SAVE20") {
          resolve({ valid: true, discount: 20 });
        } else {
          resolve({ valid: false, error: "Invalid coupon code" });
        }
      }, 1000);
    });
  };

  const handleApplyCoupon = async () => {
    if (!couponCode) {
      setCouponError("Please enter a coupon code");
      return;
    }

    setIsApplying(true);
    setCouponError("");

    try {
      const result = await validateCoupon(couponCode);
      if (result.valid) {
        const discount = (course?.offerPrice * result.discount) / 100;
        setDiscountedPrice(course?.offerPrice - discount);
        setCouponError("");
      } else {
        setCouponError(result.error);
        setDiscountedPrice(null);
      }
    } catch (error) {
      setCouponError("Error applying coupon");
    } finally {
      setIsApplying(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleModule = (index) => {
    setExpandedModules((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  const courseFeatures = [
    {
      icon: <Monitor className="w-5 h-5 text-green-500" />,
      text: "Computer & Mobile Access",
    },
    {
      icon: <Clock className="w-5 h-5 text-green-500" />,
      text: "2 Year Course Access",
    },
    {
      icon: <GraduationCap className="w-5 h-5 text-green-500" />,
      text: "Certificate of Completion",
    },
    {
      icon: <Users className="w-5 h-5 text-green-500" />,
      text: "Premium Community Access",
    },
    {
      icon: <Tag className="w-5 h-5 text-green-500" />,
      text: "Special Discounts in Workshops",
    },
  ];



  return (
    <>
    {isOpenSuccessPayment && <SuccessPopup course={course} onClose={setIsOpenSuccessPayment}/>}
    {!isUserLoggedIn && <AuthComponent onClose={setIsOpenSuccessPayment}/>}
    <div className="  scale-90 md:scale-100 md:bg-gradient-to-br -mt-8 md:-mt-0 md:py-16 from-gray-50 to-gray-100">
      <div className="container mx-auto  md:px-4 py-8 lg:py-12">
        <div className="grid lg:grid-cols-3 gap-8">
          {/* Left Column - Course Content */}
          <div className="lg:col-span-2 space-y-8">
            {/* Course Header */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-xl p-6 shadow-lg"
            >
              <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
                {course?.title}
              </h1>
              <p className="text-lg text-gray-600 mb-6">
                {course?.shortDescription}
              </p>
              <div className="flex flex-wrap gap-6 text-gray-600">
                <div className="flex items-center gap-2">
                  <Clock className="w-5 h-5 text-green-500" />
                  <span>{courseData.details.totalDuration}</span>
                </div>
              </div>
            </motion.div>

            {/* Video Preview */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="aspect-video bg-gray-900 rounded-xl overflow-hidden relative group cursor-pointer"
            >
              <img
                src={course ? course?.thumbnailImage : "https://i.ibb.co/sVjNDYB/THUMB-TEMP-illustrator.jpg"}
                alt="Course preview"
                className="w-full h-full object-cover opacity-75 group-hover:opacity-50 transition-opacity"
              />
              <motion.div
                className="absolute inset-0 flex items-center justify-center"
                whileHover={{ scale: 1.1 }}
              >
                <Play className="w-20 h-20 text-white opacity-90" />
              </motion.div>
            </motion.div>
            

            {/* Course Description */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-xl p-6 shadow-lg"
            >
              <h2 className="text-2xl font-bold text-gray-900 mb-4">
                About This Course
              </h2>
              <p className="text-gray-600 leading-relaxed">
                {course?.description}
              </p>
            </motion.div>

            {/* Course Structure */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-white rounded-xl shadow-lg overflow-hidden"
            >
              <div className="bg-gradient-to-r from-indigo-600 to-indigo-800 p-6">
                <h2 className="text-2xl font-bold text-white">
                  Course Content
                </h2>
                <p className="text-indigo-200 mt-2">
                  {courseData.details.totalLectures} lectures •{" "}
                  {courseData.details.totalDuration} total length
                </p>
              </div>

              <div className="divide-y divide-gray-200">
                {courseStructure && courseStructure.map((module, index) => (
                  <div key={index} className="bg-white">
                    <button
                      onClick={() => toggleModule(index)}
                      className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50"
                    >
                      <div className="flex items-center gap-3">
                        <BookOpen className="w-5 h-5 text-indigo-600" />
                        <span className="font-semibold text-gray-900">
                          {module.module}
                        </span>
                      </div>
                      <ChevronDown
                        className={`w-5 h-5 text-gray-500 transition-transform ${
                          expandedModules.has(index) ? "rotate-180" : ""
                        }`}
                      />
                    </button>

                    <AnimatePresence>
                      {expandedModules.has(index) && (
                        <motion.div
                          initial={{ height: 0 }}
                          animate={{ height: "auto" }}
                          exit={{ height: 0 }}
                          className="overflow-hidden"
                        >
                          <div className="px-6 pb-4 space-y-2">
                            {module.lectures.map((lecture, lectureIndex) => (
                              <div
                                key={lectureIndex}
                                className="flex items-center justify-between p-3 rounded-lg hover:bg-gray-50"
                              >
                                <div className="flex items-center gap-3">
                                  <Video className="w-5 h-5 text-indigo-500" />
                                  <span className="text-gray-700">
                                    {lecture.title}
                                  </span>
                                  {lecture.isPreview && (
                                    <span className="px-2 py-1 text-xs bg-indigo-100 text-indigo-600 rounded-full">
                                      Preview
                                    </span>
                                  )}
                                </div>
                                <span className="text-gray-500">
                                  {lecture.duration}
                                </span>
                              </div>
                            ))}
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>

          {/* Right Column - Fixed Purchase Panel */}
          <div className="lg:col-span-1">
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className={`bg-white rounded-xl shadow-lg p-6 max-w-full ${
                isScrolled ? "lg:fixed lg:top-4 lg:w-[calc(33.333%-2rem)]" : ""
              }`}
            >
              <div className="bg-gradient-to-br from-indigo-600 to-indigo-800 -m-6 mb-6 p-6 rounded-t-xl">
                <div className="flex items-baseline mb-4">
                  <span className="text-4xl font-bold text-white">
                    ₹{discountedPrice || course?.offerPrice}
                  </span>
                  <span className="ml-3 text-lg line-through text-indigo-200">
                    ₹{course?.actualPrice}
                  </span>
                </div>
                {/* <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="w-full bg-white text-indigo-600 py-3 rounded-lg font-semibold hover:bg-indigo-50 transition flex items-center justify-center gap-2"
                >
                  <ShoppingCart className="w-5 h-5" />
                  Enroll Now
                </motion.button> */}
                <PaymentButton courseDetails={{courseId:course?.courseId,id:course?.id}} setIsOpenSuccessPayment={setIsOpenSuccessPayment} isUserLoggedIn={isUserLoggedIn} setIsUserLoggedIn={setIsUserLoggedIn} amount={discountedPrice || course?.offerPrice} />
              </div>

              <div className="space-y-4">
                {courseFeatures.map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-center gap-3"
                  >
                    {feature.icon}
                    <span className="text-gray-600">{feature.text}</span>
                  </motion.div>
                ))}

                <div className="pt-4 border-t">
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2 bg-gray-50 rounded-lg p-3">
                      <Ticket className="w-5 h-5 text-indigo-500 flex-shrink-0" />
                      <input
                        type="text"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
                        placeholder="Enter Coupon Code"
                        className="bg-transparent flex-grow focus:outline-none text-gray-700 min-w-0"
                      />
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={handleApplyCoupon}
                        disabled={isApplying}
                        className="px-3 py-1 bg-indigo-100 text-indigo-600 rounded-lg hover:bg-indigo-200 transition flex-shrink-0 disabled:opacity-50"
                      >
                        {isApplying ? "Applying..." : "Apply"}
                      </motion.button>
                    </div>
                    {couponError && (
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="text-red-500 text-sm"
                      >
                        {couponError}
                      </motion.p>
                    )}
                    {discountedPrice && (
                      <motion.p
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="text-green-500 text-sm"
                      >
                        Coupon applied successfully!
                      </motion.p>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
          </div>
        </div>
      </div>
      </>
   
  );
};

export default CourseDetails;
