const { useMemo } = require("react");

const useCourseProgress = (mockData) => {

    
  return useMemo(() => {
    const totalLectures = mockData.reduce((acc, module) => acc + module.lectures.length, 0);
    const completedLectures = mockData.reduce((acc, module) => 
      acc + module.lectures.filter(lecture => lecture.status).length, 0);
    
    return Math.round((completedLectures / totalLectures) * 100);
  }, [mockData]);
};

export default useCourseProgress
