import { useSelector } from "react-redux";
import { useMemo } from "react";

const useGetCourseStructure = (courseId) => {
  const modules = useSelector((state) => state.course.modules);
  const lectures = useSelector((state) => state.course.lecture);





  const courseStructure = useMemo(() => {
    const filteredModules =
      modules?.filter((module) => module?.courseFID === courseId) || [];

    return filteredModules.map((module) => ({
      module: module?.module_title, // Assuming the module has a 'name' property
      lectures: lectures
        .filter((lecture) => lecture?.course_doc_id === module?.id)
        .map((lecture) => ({
          title: lecture?.lecture_title,
          duration: lecture?.duration || "23min",
          isPreview: lecture?.isPreview || false,
        })),
    }));
  }, [courseId, modules, lectures]);

  //   console.log(courseStructure)

  return courseStructure;
};

export default useGetCourseStructure;
