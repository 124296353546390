const ErrorBoundary = () => {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-red-50">
        <h1 className="text-2xl font-bold text-red-600">Oops! Something went wrong</h1>
        <p className="mt-4 text-gray-700">We couldn't find the page you're looking for.</p>
        <button 
          onClick={() => window.location.href = '/'} 
          className="mt-6 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Go to Home
        </button>
      </div>
    );
  };
  

  export default ErrorBoundary