import React from 'react';
import { X, CheckCircle, ExternalLink } from 'lucide-react';
import { motion } from 'framer-motion';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const SuccessPopup = ({ 
  courseName="AI/ML", 
  course,
  onClose 
}) => {
    




  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm">
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="w-full max-w-md bg-white rounded-2xl shadow-2xl overflow-hidden"
      >
        <div className="relative p-6">
          <button 
            onClick={()=>onClose(false)} 
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
          >
            <X size={24} />
          </button>

          <div className="flex flex-col items-center space-y-4">
            {/* <CheckCircle 
              size={64} 
              className="text-violet-600 animate-pulse"
            /> */}

<DotLottieReact
      src="https://lottie.host/0e9e05a3-e30f-4d8e-8bf5-cc0ab0fdf283/kCg6RuNS8B.lottie"
      loop
      autoplay
      className='-space-y-7'
    />
            


            <h2 className="text-2xl font-bold text-gray-800 text-center">
              Congratulations!
            </h2>

            <p className="text-gray-600 text-center">
              You successfully purchased <br/>
              <span className="font-bold text-violet-600">
                {course && course?.title} Course
              </span>
            </p>

            <div className="w-full bg-violet-50 border border-violet-200 rounded-xl p-4 space-y-3">
              <h3 className="text-lg font-semibold text-violet-800 text-center">
                Course Access Details
              </h3>

              <div className="space-y-2 text-sm text-gray-700 text-center">
                <p>🎓 Access your course on our LMS platform.</p>
                <div className="flex justify-center items-center space-x-2">
                  <span className="font-semibold">Platform Link:</span>
                  <a 
                    href="https://learn.digitaldo.in" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-violet-600 hover:underline"
                  >
                    learn.digitaldo.in
                  </a>
                </div>
                <p>📍 Use the same Google account to sign up.</p>
                <p>🔍 Find your courses in "My Courses.</p>
              </div>
            </div>

            <button 
              onClick={() => {

                const learnDomain =
      process.env.NODE_ENV === "production"
        ? `https://learn.${window.location.hostname}`
        : `http://learn.${window.location.hostname}:3000`;
                
                window.open(learnDomain, '_blank')}}
              className="w-full py-3 bg-violet-600 hover:bg-violet-700 text-white rounded-lg transition-colors flex items-center justify-center space-x-2"
            >
              <ExternalLink size={20} />
              <span>Go to LMS Platform</span>
            </button>

            <p className="text-sm text-gray-500 italic text-center">
              Happy Learning! 🚀
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default SuccessPopup;