import React, { useEffect, useState } from 'react'
import { ClockIcon, StarIcon, BookOpenIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CourseCard = ({course=""}) => {



  const getLevelColor = (level) => {
    switch(level) {
      case 'Beginner': return 'bg-green-100 text-green-800';
      case 'Intermediate': return 'bg-blue-100 text-blue-800';
      case 'Advanced': return 'bg-purple-100 text-purple-800';
      default: return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all hover:shadow-xl hover:-translate-y-2 duration-300">
      {/* Thumbnail with Responsive Sizing */}
      <div className="w-full aspect-video">
        <img 
          src={course?.thumbnailImage} 
          alt={course?.title || 'Course Thumbnail'} 
          className="w-full h-full object-cover"
        />
      </div>

      {/* Course Details */}
      <div className="p-6">
        <div className="flex justify-between items-center pb-4">
          <h3 className="text-xl font-bold text-gray-800 truncate pr-2">{course?.title}</h3>
        </div>

        <p className="text-gray-500 mb-5 text-sm line-clamp-2">{course?.shortDescription}</p>

        {/* Course Stats */}
        <div className="flex justify-between items-center py-2">
          <div className="flex items-center">
            <span className={`px-3 py-1 rounded-full text-xs font-semibold ${getLevelColor(course?.level)}`}>
              {course.level}
            </span>
          </div>
          <div className="flex items-center gap-x-3 text-xl text-violet-600 font-semibold">
            <span className="font-normal line-through text-gray-300">₹{course?.actualPrice}</span>
            <span className="">₹{course?.offerPrice}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard