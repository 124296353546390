import { configureStore } from "@reduxjs/toolkit";
import courseReducer from "./reduxSlices/courseSlice"
import userReducer from "./reduxSlices/userSlice"


export const appStore=configureStore({
    reducer:{
        course:courseReducer,
        users:userReducer
    },
})