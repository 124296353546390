import { createSlice } from "@reduxjs/toolkit";



export const userSlice = createSlice({
    name:"users",
    initialState:{
        TotalUsers:[],
        user:[],
        purchaseCourse:[],
        myCourse:[]
    },
    reducers:{
        addTotalUser:(state,action)=>{
            state.TotalUsers.push(action.payload) 

        },
        addUser:(state,action)=>{
            state.user=action.payload
        },
        addPurchasedCourse:(state,action)=>{
            state.purchaseCourse=action.payload
        },
        addMyCourse:(state,action)=>{
            state.myCourse=action.payload
        }
    }
})

export const {addUser,addTotalUser,addMyCourse,addPurchasedCourse}= userSlice.actions
export default userSlice.reducer