import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  ChartBarIcon,
  CurrencyRupeeIcon,
  BookOpenIcon,
  PlusIcon,
  UsersIcon,
  ClockIcon,
  ArrowTrendingUpIcon,
} from "@heroicons/react/24/outline";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import CreateCourse from "./CreateCourse";
import { useLocation } from "react-router-dom";

// Mock Data with Extended Information
const MOCK_DASHBOARD_DATA = {
  totalCoursesSold: 245,
  totalRevenue: 487500,
  activeStudents: 1245,
  recentCourses: [
    {
      id: 1,
      name: "Web Development Bootcamp",
      students: 75,
      revenue: 112500,
      progress: 65,
    },
    {
      id: 2,
      name: "UI/UX Design Masterclass",
      students: 55,
      revenue: 82500,
      progress: 45,
    },
    {
      id: 3,
      name: "Python Programming",
      students: 65,
      revenue: 97500,
      progress: 55,
    },
  ],
  revenueHistory: [
    { month: "Jan", revenue: 35000 },
    { month: "Feb", revenue: 42000 },
    { month: "Mar", revenue: 55000 },
    { month: "Apr", revenue: 65000 },
    { month: "May", revenue: 78000 },
  ],
};

const AdminDashboard = () => {

  const location = useLocation()
  const {course}= location?.state  || false

  
  
  const [activeSection, setActiveSection] = useState("overview");
  const [handleSideBar, setHandleSideBar] = useState(true);
  


  const dashboardSections = [
    {
      id: "overview",
      icon: <ChartBarIcon className="w-6 h-6 text-violet-600" />,
      label: "Overview",
    },
    {
      id: "courses",
      icon: <BookOpenIcon className="w-6 h-6 text-violet-600" />,
      label: "Courses",
    },
    {
      id: "create-course",
      icon: <PlusIcon className="w-6 h-6 text-violet-600" />,
      label: "Create Course",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  return (
    <div className="flex min-h-screen bg-gray-50 ">
      {/* Responsive Sidebar */}
      {handleSideBar ? (
        <div className="absolute left-48 top-4 z-10">
          <i
            onClick={() => setHandleSideBar(!handleSideBar)}
            class="fa-solid text-2xl cursor-pointer text-gray-500  fa-chevron-left "
          ></i>
        </div>
      ) : (
        <div className="absolute left-0 top-8 z-10">
          <i
            onClick={() => setHandleSideBar(!handleSideBar)}
            class="fa-solid text-2xl cursor-pointer text-violet-500  fa-chevron-right "
          ></i>
        </div>
      )}
      {handleSideBar && (
        <motion.div
          initial={{ x: -50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 100 }}
          className="w-64 bg-white shadow-md p-4 hidden md:block"
        >
          <h2 className="text-2xl font-bold mb-8 text-gray-800">Admin Panel</h2>
          {dashboardSections.map((section) => (
            <motion.div
              key={section.id}
              onClick={() => setActiveSection(section.id)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`
              flex items-center p-3 mb-2 rounded-lg cursor-pointer
              ${
                activeSection === section.id
                  ? "bg-violet-100 text-violet-800"
                  : "hover:bg-gray-100"
              }
            `}
            >
              {section.icon}
              <span className="ml-3">{section.label}</span>
            </motion.div>
          ))}
        </motion.div>
      )}

      {/* Main Content with Animations */}
      <div className="flex-1 p-4 md:p-8">
        <AnimatePresence mode="wait">
          {activeSection === "overview" && (
            <motion.div
              key="overview"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
            >
              {/* Revenue Card */}
              <motion.div
                variants={itemVariants}
                className="bg-gradient-to-bl text-white from-purple-400 to-violet-500 p-6 rounded-xl shadow-md "
              >
                <div className="flex items-center justify-between ">
                  <CurrencyRupeeIcon className="w-10 h-10 text-white" />
                  <div className="text-right">
                    <h3 className="text-gray-100">Total Revenue</h3>
                    <p className="text-2xl font-bold">
                      ₹{MOCK_DASHBOARD_DATA.totalRevenue.toLocaleString()}
                    </p>
                  </div>
                </div>
              </motion.div>

              {/* Courses Sold Card */}
              <motion.div
                variants={itemVariants}
                className="bg-white p-6 rounded-xl shadow-md"
              >
                <div className="flex items-center justify-between">
                  <BookOpenIcon className="w-10 h-10 text-blue-500" />
                  <div className="text-right">
                    <h3 className="text-gray-500">Courses Sold</h3>
                    <p className="text-2xl font-bold">
                      {MOCK_DASHBOARD_DATA.totalCoursesSold}
                    </p>
                  </div>
                </div>
              </motion.div>

              {/* Active Students Card */}
              <motion.div
                variants={itemVariants}
                className="bg-gradient-to-bl from-green-200 to-green-300 p-6 text-black rounded-xl shadow-md"
              >
                <div className="flex items-center justify-between">
                  <UsersIcon className="w-10 h-10 text-green-900" />
                  <div className="text-right">
                    <h3 className="text-green-900">Active Students</h3>
                    <p className="text-2xl font-bold">
                      {MOCK_DASHBOARD_DATA.activeStudents}
                    </p>
                  </div>
                </div>
              </motion.div>

              {/* Revenue Chart */}
              <motion.div
                variants={itemVariants}
                className="bg-white p-6 rounded-xl shadow-md col-span-full"
              >
                <div className="flex items-center mb-4">
                  <ArrowTrendingUpIcon className="w-6 h-6 text-green-600 mr-2" />
                  <h3 className="text-lg font-semibold text-gray-700">
                    Revenue Trend
                  </h3>
                </div>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={MOCK_DASHBOARD_DATA.revenueHistory}>
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="revenue"
                      stroke="#8884d8"
                      strokeWidth={3}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </motion.div>

              {/* Recent Courses */}
              <motion.div
                variants={itemVariants}
                className="bg-white p-6 rounded-xl shadow-md col-span-full"
              >
                <div className="flex items-center mb-4">
                  <ClockIcon className="w-6 h-6 text-orange-600 mr-2" />
                  <h3 className="text-lg font-semibold text-gray-700">
                    Recent Courses
                  </h3>
                </div>
                <div className="space-y-4">
                  {MOCK_DASHBOARD_DATA.recentCourses.map((course) => (
                    <motion.div
                      key={course.id}
                      whileHover={{ scale: 1.02 }}
                      className="flex justify-between items-center bg-gray-100 p-4 rounded-lg"
                    >
                      <div>
                        <p className="font-medium">{course.name}</p>
                        <p className="text-sm text-gray-500">
                          {course.students} Students
                        </p>
                      </div>
                      <div className="text-right">
                        <p className="font-semibold text-green-600">
                          ₹{course.revenue.toLocaleString()}
                        </p>
                        <p className="text-sm text-gray-500">
                          {course.progress}% Completed
                        </p>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </motion.div>
          )}
          {activeSection === "create-course" && (
            <motion.div
              key="overview"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className=""
            >
              <CreateCourse/>



            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AdminDashboard;
