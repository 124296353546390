import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "./reduxSlices/userSlice";

const useUserFromLocal = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const getUser = useSelector((state) => state.users.user);

  // Get data from localStorage and set to state
  useEffect(() => {
    try {
      const storedUser = localStorage.getItem("getUserData");
      if (storedUser && typeof storedUser === "string") {
        const parsedUser = JSON.parse(storedUser);
        setUserData(parsedUser);
      }
    } catch (error) {
      localStorage.removeItem("getUserData");
    }
  }, []);

  // Add to Redux if not already present
  useEffect(() => {
    if (userData && (!getUser || getUser.length === 0)) {
      dispatch(addUser(userData));
    }
  }, [userData, dispatch, getUser]);

  return userData;
};

export default useUserFromLocal;