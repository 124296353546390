import React, { useState } from 'react';
import { X, Plus, Trash2, Link, FileText } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { uploadCourseLecture } from '../../../utils/firebaseDataFetch/uploadCourse';

const CreateLecture = ({ setAddLectureProps, setHandleCreateLectureModal, props }) => {
  const { courseId, id } = props;

  const [formData, setFormData] = useState({
    lecture_title: "",
    courseId: courseId || "",
    course_doc_id: id || "",
    videoLink: "",
    order: "", // Added order field
    notes_links: []
  });

  const [newNoteData, setNewNoteData] = useState({
    title: "",
    url: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNoteDataChange = (e) => {
    const { name, value } = e.target;
    setNewNoteData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddNoteLink = () => {
    if (newNoteData.url.trim() && newNoteData.title.trim()) {
      setFormData(prevState => ({
        ...prevState,
        notes_links: [...prevState.notes_links, { ...newNoteData }]
      }));
      setNewNoteData({ title: "", url: "" });
    }
  };

  const handleDeleteNoteLink = (indexToDelete) => {
    setFormData(prevState => ({
      ...prevState,
      notes_links: prevState.notes_links.filter((_, index) => index !== indexToDelete)
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setHandleCreateLectureModal(false);
    setAddLectureProps(false);
    uploadCourseLecture(formData);
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <motion.div
          className="relative w-full max-w-2xl bg-white rounded-xl shadow-xl overflow-hidden"
          initial={{ scale: 0.9, y: 20 }}
          animate={{ scale: 1, y: 0 }}
          transition={{ type: "spring", damping: 25, stiffness: 300 }}
        >
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => {
              setHandleCreateLectureModal(false);
              setAddLectureProps(false);
            }}
            className="absolute right-4 top-4 p-2 hover:bg-red-100 rounded-full transition-colors"
          >
            <X className="text-red-600" size={24} />
          </motion.button>

          <div className="p-6 md:p-8">
            <motion.h2 
              className="text-2xl font-bold text-gray-800 mb-6"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              Create New Lecture
            </motion.h2>
          
            <form onSubmit={handleSubmit} className="space-y-6">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.1 }}
                className="grid grid-cols-1 md:grid-cols-2 gap-6"
              >
                {/* Lecture Title */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Lecture Title
                  </label>
                  <input
                    name="lecture_title"
                    value={formData.lecture_title}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
                    placeholder="Enter lecture title"
                    required
                  />
                </div>

                {/* Order Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Lecture Order
                  </label>
                  <input
                    name="order"
                    type="number"
                    min="1"
                    value={formData.order}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
                    placeholder="Enter lecture order (e.g., 1, 2, 3)"
                    required
                  />
                </div>
              </motion.div>

              {/* Video Link */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.2 }}
              >
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Video Link
                </label>
                <div className="flex items-center space-x-2">
                  <Link size={20} className="text-gray-500" />
                  <input
                    name="videoLink"
                    value={formData.videoLink}
                    onChange={handleInputChange}
                    className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
                    placeholder="Enter video URL"
                    type="url"
                  />
                </div>
              </motion.div>

              {/* Notes Links */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.3 }}
              >
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Notes/Documents
                </label>
                <div className="space-y-3">
                  <div className="flex flex-col md:flex-row gap-3">
                    <div className="flex-1">
                      <div className="flex items-center space-x-2">
                        <FileText size={20} className="text-gray-500" />
                        <input
                          name="title"
                          value={newNoteData.title}
                          onChange={handleNoteDataChange}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
                          placeholder="Enter document title"
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="flex items-center space-x-2">
                        <Link size={20} className="text-gray-500" />
                        <input
                          name="url"
                          value={newNoteData.url}
                          onChange={handleNoteDataChange}
                          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-violet-500 focus:border-violet-500"
                          placeholder="Enter document URL"
                          type="url"
                        />
                      </div>
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      type="button"
                      onClick={handleAddNoteLink}
                      disabled={!newNoteData.url || !newNoteData.title}
                      className="px-4 py-2 bg-violet-600 text-white rounded-lg hover:bg-violet-700 disabled:bg-violet-300 disabled:cursor-not-allowed flex items-center justify-center space-x-1"
                    >
                      <Plus size={20} />
                      <span>Add</span>
                    </motion.button>
                  </div>
                </div>

                {/* Notes Preview */}
                <AnimatePresence>
                  {formData.notes_links.length > 0 && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      className="space-y-2 mt-4"
                    >
                      <h3 className="text-sm font-medium text-gray-700">Added Notes/Documents:</h3>
                      <div className="max-h-40 overflow-y-auto">
                        {formData.notes_links.map((note, index) => (
                          <motion.div
                            key={index}
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 20 }}
                            className="flex items-center justify-between p-3 bg-gray-50 rounded-lg mb-2"
                          >
                            <div className="flex-1 mr-4">
                              <h4 className="font-medium text-gray-900">{note.title}</h4>
                              <a
                                href={note.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-violet-600 hover:text-violet-800 text-sm truncate block"
                              >
                                {note.url}
                              </a>
                            </div>
                            <motion.button
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              type="button"
                              onClick={() => handleDeleteNoteLink(index)}
                              className="text-red-500 hover:text-red-700 p-1"
                            >
                              <Trash2 size={16} />
                            </motion.button>
                          </motion.div>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>

              {/* Submit Button */}
              <motion.div
                className="flex justify-end pt-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="submit"
                  className="px-6 py-2 bg-violet-600 text-white rounded-lg hover:bg-violet-700 transition-colors"
                >
                  Create Lecture
                </motion.button>
              </motion.div>
            </form>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CreateLecture;