import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";
import useGetCourses from "../../utils/firebaseDataFetch/useGetCourses";
import { useSelector } from "react-redux";
import CreateCourseModal from "./CreateCourseModal";
import { useNavigate } from "react-router-dom";
import UpdateCourse from "./UpdateCourse";

const CreateCourse = () => {
  useGetCourses();
  const courses = useSelector((state) => state.course.courses);
  // console.log(courses)
  const navigate = useNavigate();
  // const [isCreateCourseOpen, setIsCreateCourseOpen] = useState(false)

  // const handleCreateCourse = () => {
  //   isCreateCourseOpen(true)
  // }

  const handleEditCourse = (course) => {
    navigate("updateCourse", { state: { course } });

    // Implement edit logic
    // console.log('Editing course:', course)
  };

  const handleAddModule = (id, courseId, title) => {
    navigate("addModule", { state: { id, courseId, title } });
  };

  const handleDeleteCourse = (courseId) => {
    // Implement delete logic
    // console.log('Deleting course:', courseId)
  };

  return (
    <>
      <div className="container mx-auto p-4">
        <motion.div
          className="flex justify-between items-center mb-6"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-2xl font-bold text-gray-800">
            Course Management
          </h2>
          <motion.button
            onClick={() => navigate("createcourse")}
            className="bg-blue-600 text-white px-4 py-2 rounded-full flex items-center hover:bg-blue-700 transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <PlusIcon className="w-5 h-5 mr-2" />
            Create Course
          </motion.button>
        </motion.div>

        <motion.div
          className="overflow-x-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-100">
              <tr>
                <th className="p-3 text-left">Title</th>
                <th className="p-3 text-left">Price</th>
                <th className="p-3 text-left">Level</th>
                <th className="p-3 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {courses.map((course) => (
                <motion.tr
                  key={course?.id}
                  className="border-b hover:bg-gray-50"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <td className="p-3">{course?.title}</td>
                  <td className="p-3">{course?.offerPrice}</td>
                  <td className="p-3">{course?.level}</td>
                  <td className="p-3 flex justify-center space-x-2">
                    <motion.button
                      onClick={() =>
                        handleAddModule(
                          course?.id,
                          course?.courseId,
                          course?.title
                        )
                      }
                      className="text-white hover:text-gray-100 px-2 py-1  rounded-lg bg-green-700 flex flex-wrap items-center justify-between gap-x-1"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <DocumentTextIcon className="w-5 h-5" />
                      Add Module
                    </motion.button>
                    <motion.button
                      onClick={() => handleEditCourse(course)}
                      className="text-blue-500 hover:text-blue-700"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <PencilIcon className="w-5 h-5 " />
                    </motion.button>
                    <motion.button
                      onClick={() => handleDeleteCourse(course?.courseId)}
                      className="text-red-500 hover:text-red-700"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                    >
                      <TrashIcon className="w-5 h-5" />
                    </motion.button>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </motion.div>

        {/* Create Course Modal (to be implemented) */}
        {/* {isCreateModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
         
         
          
        </div>
      )} */}
      </div>
    </>
  );
};

export default CreateCourse;
