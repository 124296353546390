import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import CreateModuleModal from "./CreateModuleModal";
import ModuleTable from "./SubCompanents/ModuleTable";


const AddModule = () => {

    const [handleCreateModuleModal,setHandleCreateModuleModal]=useState(false)
  const location = useLocation();
  const { id, courseId, title } = location?.state || {};
 
  return (
    <>
    {handleCreateModuleModal && <CreateModuleModal id={id} courseId={courseId} setHandleCreateModuleModal={setHandleCreateModuleModal}/>}
    <div className="px-24 py-10">
      <div className="space-y-5">
        <div>
          <div className="text-3xl font-semibold text-violet-700">{title}</div>
          <div className="text-gray-500">Course ID : <span className="font-semibold">{courseId}</span></div>
        </div>
        <div className="flex justify-between">
          <div className="text-lg  font-semibold text-gray-700">Module Management</div>
          <div className="bg-green-600 rounded-lg px-2 py-1 text-white cursor-pointer" onClick={()=>setHandleCreateModuleModal(!handleCreateModuleModal)}>
            + Create Module
          </div>
        </div>
      </div>
    </div>
    <div>
      <ModuleTable courseId={courseId}/>
      
      
    </div>
    </>
  );
};

export default AddModule;
