// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { FIREBASE_API } from "./constants";
import { browserLocalPersistence, getAuth, setPersistence } from "firebase/auth";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: FIREBASE_API,
  authDomain: "digital-do.firebaseapp.com",
  projectId: "digital-do",
  storageBucket: "digital-do.firebasestorage.app",
  messagingSenderId: "879443643657",
  appId: "1:879443643657:web:1175f2b6b2b8b715e097d8",
  measurementId: "G-RY89899MQ1",
 
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const firebaseDb=getFirestore(app)
export const firebaseAuth=getAuth(app)

const auth = getAuth();
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Persistence set successfully
  })
  .catch((error) => {
    console.error("Auth persistence error:", error);
  });

