import React, { useState } from "react";
import { motion } from "framer-motion";
import { Upload, X, Plus, Save, CheckCircle, Circle } from "lucide-react";
import { useNavigate } from "react-router-dom";
import uploadCourse from "../../utils/firebaseDataFetch/uploadCourse";

const CreateCoursePage = () => {

  const navigate=useNavigate()
  const [formData, setFormData] = useState({
    title: '',
    shortDescription: '',
    description: '',
    courseId: 'DD0',
    category: 'Design',
    level: "Beginner",
    actualPrice: '',
    offerPrice: '',
    isDraft: true
  });
  const [image, setImage] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDraftChange = () => {
    setFormData(prevState => ({
      ...prevState,
      isDraft: !prevState.isDraft
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionData = {
      ...formData,
      thumbnailImage: image
    };
    uploadCourse(submissionData)
    navigate("/admin")
    window.scrollTo(0,0)
    // console.log('Form Submission Data:', submissionData);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-violet-50 to-white py-12 px-4 sm:px-6 lg:px-8">
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="max-w-4xl mx-auto"
      >
        <div className="bg-white rounded-xl shadow-xl overflow-hidden">
          <div className="p-6 sm:p-10">
            <h2 className="text-3xl font-bold text-violet-800 mb-8 text-center">
              Create New Course
            </h2>

            <form onSubmit={handleSubmit} className="space-y-6">
              <motion.div variants={itemVariants} className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Course Title</label>
                  <input
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 bg-violet-50 rounded-lg focus:ring-2 focus:ring-violet-500 transition"
                    placeholder="Enter course title"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Course ID</label>
                  <input
                    name="courseId"
                    value={formData.courseId}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 bg-violet-50 rounded-lg focus:ring-2 focus:ring-violet-500 transition"
                    placeholder="Unique course identifier"
                    required
                  />
                </div>
              </motion.div>

              <motion.div variants={itemVariants} className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Short Description</label>
                  <textarea
                    name="shortDescription"
                    value={formData.shortDescription}
                    onChange={handleInputChange}
                    rows="4"
                    className="w-full px-4 py-3 bg-violet-50 rounded-lg focus:ring-2 focus:ring-violet-500 transition"
                    placeholder="Brief course overview"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Detailed Description</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    rows="4"
                    className="w-full px-4 py-3 bg-violet-50 rounded-lg focus:ring-2 focus:ring-violet-500 transition"
                    placeholder="Comprehensive course description"
                    required
                  />
                </div>
              </motion.div>

              <motion.div variants={itemVariants} className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Category</label>
                  <select
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 bg-violet-50 rounded-lg focus:ring-2 focus:ring-violet-500 transition"
                  >
                    <option value="Design">Design</option>
                    <option value="Personal Development">Personal Development</option>
                    <option value="Marketing">Marketing</option>
                    <option value="UI Development">UI Development</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Actual Price</label>
                  <input
                    name="actualPrice"
                    type="number"
                    value={formData.actualPrice}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 bg-violet-50 rounded-lg focus:ring-2 focus:ring-violet-500 transition"
                    placeholder="Course price"
                    required
                    min="0"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Offer Price</label>
                  <input
                    name="offerPrice"
                    type="number"
                    value={formData.offerPrice}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 bg-violet-50 rounded-lg focus:ring-2 focus:ring-violet-500 transition"
                    placeholder="Discounted price"
                    min="0"
                  />
                </div>
              </motion.div>

              <motion.div variants={itemVariants} className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Level</label>
                  <select
                    name="level"
                    value={formData.level}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 bg-violet-50 rounded-lg focus:ring-2 focus:ring-violet-500 transition"
                  >
                    <option value="Beginner">Beginner</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Advanced">Advanced</option>
                  </select>
                </div>

                <div className="flex items-center space-x-4">
                  <label className="text-sm font-medium text-gray-700">Draft Mode</label>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="button"
                    onClick={handleDraftChange}
                    className="p-2 rounded-full hover:bg-violet-50 transition-colors"
                  >
                    {formData.isDraft ? (
                      <CheckCircle className="w-6 h-6 text-violet-600" />
                    ) : (
                      <Circle className="w-6 h-6 text-gray-400" />
                    )}
                  </motion.button>
                  <span className="text-sm text-gray-500">
                    {formData.isDraft ? "Draft Mode" : "Publish Mode"}
                  </span>
                </div>
              </motion.div>

              <motion.div variants={itemVariants}>
                <label className="block text-sm font-medium text-gray-700 mb-2">Course Thumbnail</label>
                <motion.div 
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.99 }}
                  className="border-2 border-dashed border-violet-300 rounded-xl p-6 text-center cursor-pointer"
                >
                  <input
                    type="file"
                    accept="image/webp"
                    onChange={handleImageUpload}
                    className="hidden"
                    id="thumbnail-upload"
                  />
                  <label htmlFor="thumbnail-upload" className="cursor-pointer block">
                    {image ? (
                      <div className="relative">
                        <motion.img 
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          src={image} 
                          alt="Course Thumbnail" 
                          className="max-h-64 w-full object-cover rounded-lg"
                        />
                        <motion.button 
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            setImage(null);
                          }}
                          className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full shadow-lg"
                        >
                          <X className="w-4 h-4" />
                        </motion.button>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center text-gray-600">
                        <Upload className="w-12 h-12 mb-4 text-violet-500" />
                        <p>Drag & Drop or Click to Upload Course Thumbnail</p>
                        <span className="text-sm text-gray-500 mt-2">
                          WEBP (max 3MB)
                        </span>
                      </div>
                    )}
                  </label>
                </motion.div>
              </motion.div>

              <motion.div 
                variants={itemVariants}
                className="flex flex-col sm:flex-row justify-end space-y-4 sm:space-y-0 sm:space-x-4 mt-8"
              >
                <motion.button 
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="button" 
                  onClick={()=>{navigate("/admin")}}
                  className="w-full sm:w-auto px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition flex items-center justify-center"
                >
                  <X className="w-5 h-5 mr-2" />
                  Cancel
                </motion.button>
                <motion.button 
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit" 
                  className="w-full sm:w-auto px-6 py-3 bg-violet-700 text-white rounded-lg hover:bg-violet-800 transition flex items-center justify-center"
                >
                  {formData.isDraft ? (
                    <>
                      <Save className="w-5 h-5 mr-2" />
                      Save as Draft
                    </>
                  ) : (
                    <>
                      <Plus className="w-5 h-5 mr-2" />
                      Publish Course
                    </>
                  )}
                </motion.button>
              </motion.div>
            </form>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CreateCoursePage;